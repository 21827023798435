import IAssistant from '../types/IAssistant'
import { dateToISO } from '../utils/date'
import api from './api'
import { endpointsCreator } from './endpoints'
import callResetPassword from './reset-password'

interface ISecretaries {
	doctorCpf: string
	secretaryId: string | undefined
	secretaryCpf: string
	assistant: IAssistant
}

interface IPostSecretaryCreation {
	name: string
	cpf: string
	email: string
	phone: string
}

export const getSecretaries = async ({ doctorCpf }: ISecretaries) => {
	const endpoints = endpointsCreator(doctorCpf)
	try {
		const response = await api.get(endpoints.SECRETARY.GET_DOCTOR_SECRETARIES)
		return response
	} catch (err) {
		return err.response
	}
}

export const getSecretaryInfo = async ({ secretaryCpf }: ISecretaries) => {
	const endpoints = endpointsCreator(secretaryCpf)

	try {
		const response = await api.get(endpoints.SECRETARY.GET_SECRETARY)
		return response
	} catch (err) {
		return err.response
	}
}

export const getSecretaryDoctors = async ({ secretaryCpf }: ISecretaries) => {
	const endpoints = endpointsCreator(secretaryCpf)

	try {
		const response = await api.get(endpoints.SECRETARY.GET_SECRETARY_DOCTORS)
		return response
	} catch (err) {
		return err.response
	}
}

export const confirmSecretaryRegistration = async ({ secretaryId }: ISecretaries) => {
	const endpoints = endpointsCreator(secretaryId)
	try {
		const response = await api.post(endpoints.SECRETARY.POST_SECRETARY_CONFIRM, {
			id: secretaryId
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const postSecretaryCreation = async ({ email, name, cpf, phone }: IPostSecretaryCreation) => {
	const endpoints = endpointsCreator()
	try {
		const response = await api.post(endpoints.SECRETARY.POST_SECRETARY_CREATION, {
			email,
			name,
			secretaryCpf: cpf.replace(/\D/g, ''),
			phone: phone.replace(/\D/g, '')
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const createSecretary = async ({
	name,
	cpf,
	birthDay,
	phone,
	email,
	optins: consent,
	token = '',
	password = ''
}: IAssistant) => {
	if (cpf) {
		const endpoints = endpointsCreator(cpf)
		try {
			await api.put(
				endpoints.SECRETARY.PUT_SECRETARY_CONFIRM,
				{
					email,
					name,
					secretaryCpf: cpf.replace(/\D/g, ''),
					birthDay: birthDay && dateToISO(birthDay),
					phone: phone.replace(/\D/g, ''),
					optins: ['use']
				},
				{
					headers: {
						Authorization: token
					}
				}
			)

			const resPassword = await callResetPassword(cpf.replace(/\D/g, ''), password, token)
			return resPassword
		} catch (err) {
			return err.response
		}
	}
}

export const updateSecretary = async ({ name, cpf, birthDay, phone, email, optins }: IAssistant) => {
	if (cpf) {
		const endpoints = endpointsCreator()
		try {
			const response = await api.put(endpoints.SECRETARY.UPDATE_SECRETARY, {
				name,
				secretaryCpf: cpf.replace(/\D/g, ''),
				birthDay: birthDay && dateToISO(birthDay),
				phone: phone.replace(/\D/g, ''),
				email,
				optins
			})
			return response
		} catch (err) {
			return err.response
		}
	}
}

export const putSecretaryRegistration = async (args: IAssistant) => {
	const secretary = { ...args }
	if (secretary && secretary.secretaryCpf) {
		const endpoints = endpointsCreator(secretary.secretaryCpf)
		try {
			const response = await api.put(endpoints.SECRETARY.PUT_SECRETARY_CONFIRM, {
				...secretary
			})
			return response
		} catch (err) {
			console.log(err)
			return err.response
		}
	}
}

export const updateSecretaryByDoctor = async ({ assistant, doctorCpf }: ISecretaries) => {
	const secretaryId = assistant.id
	if (secretaryId) {
		const endpoints = endpointsCreator(doctorCpf, secretaryId)

		if (assistant.cpf) {
			try {
				const response = await api.put(endpoints.SECRETARY.DOCTOR_UPDATE_SECRETARIES, {
					...assistant,
					secretaryCpf: assistant.cpf.replace(/\D/g, '')
				})
				return response
			} catch (err) {
				return err.response
			}
		}
	}
}

export const deleteSecretary = async ({ secretaryId }: ISecretaries) => {
	const endpoints = endpointsCreator(secretaryId)
	try {
		const response = await api.delete(endpoints.SECRETARY.DELETE_SECRETARY)
		return response
	} catch (err) {
		return err.response
	}
}

export const reactivateSecretary = async ({ secretaryId }: ISecretaries) => {
	const endpoints = endpointsCreator(secretaryId)
	try {
		const response = await api.put(endpoints.SECRETARY.REACTIVATE_SECRETARY, { id: secretaryId })
		return response
	} catch (err) {
		return err.response
	}
}
