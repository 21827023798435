import api from './api'

const callResetPassword = async (userName: string, newPassword: string, accessToken: string, optins?: string[]) => {
	try {
		const others = {}
		if (optins) {
			others.optins = optins
		}
		const response = await api.put(
			`/users/${userName}`,
			{
				newPassword,
				...others
			},
			{
				headers: {
					Authorization: accessToken
				}
			}
		)

		return response
	} catch (err) {
		return err.response
	}
}

export default callResetPassword
